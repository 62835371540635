<template>
    <div class="PriceDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form ref="form" label-width="80px" width="auto" size="small">
                    <el-form-item label="机构类型">
                        <el-input readonly v-model="form.deptTypeName" />
                    </el-form-item>
                    <el-form-item label="机构名称">
                        <el-input readonly v-model="form.deptName" />
                    </el-form-item>
                    <el-form-item label="商品名称">
                        <el-input readonly v-model="form.name" />
                    </el-form-item>
                    <el-form-item label="出厂价" v-if="showFactoryPriceFlag && hasPrivilege('biz.price.factory.query')">
                        <el-input readonly :value="fmtThree(form.factoryPrice)" />
                    </el-form-item>
                    <el-form-item label="批发价" v-if="hasPrivilege('biz.price.wholeSale.query')">
                        <el-input readonly :value="fmtTwo(form.wholeSalePrice)" />
                    </el-form-item>
                    <el-form-item label="零售价" v-if="hasPrivilege('biz.price.retail.query')">
                        <el-input readonly :value="fmtTwo(form.retailPrice)" />
                    </el-form-item>
                    <el-form-item
                        label="计划成本"
                        v-if="showPlanCostPricesFlag() && hasPrivilege('biz.price.plan.query')"
                    >
                        <el-input readonly :value="fmtMFour(form.planCostPrice)" />
                        <span style="color: red">计划成本每个月只可以变更一次</span>
                    </el-form-item>

                    <el-button
                        type="primary"
                        @click="handleClickAudit"
                        size="small"
                        style="margin-left: 100px"
                        :disabled="checkData"
                        >审核详情
                    </el-button>
                    <pickup-return-audit
                        ref="audit"
                        :review-privilege-flag="'menu.goods.price.review'"
                        :have-reject="false"
                        :have-review="false"
                    />
                </el-form>
            </div>
        </el-card>
    </div>
</template>
<script>
import Utils from 'js/Util';
import PickupReturnAudit from 'components/PickupReturnAudit';

export default {
    name: 'PriceDetail',
    components: { PickupReturnAudit },
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            form: {
                code: '',
                deptCode: '',
                goodsCode: '',
                reviewStatus: '',
                factoryPrice: null,
                wholeSalePrice: null,
                retailPrice: null,
                planCostPrice: null,
                deptType: null,
                deptTypeName: '',
                createTime: '',
                creator: '',
                deptName: '',
                name: '',
            },
            priceAuditDialogTable: false,
            auditCodes: [],
            reviewListData: {},
            checkData: true,
        };
    },
    mounted() {
        (async () => {
            const rst = await this.$efApi.goodsPriceChangeApi.detail(this.code);
            Utils.copyProperties(rst, this.form);
            if (this.form.reviewStatus !== 0) {
                this.checkData = false;
            }
        })();
    },
    computed: {
        showFactoryPriceFlag() {
            return this.form.deptType === 2;
        },
    },
    methods: {
        handleClickAudit() {
            this.$refs.audit.show(this.code, '/goods/price');
        },
        showPlanCostPricesFlag() {
            return this.hasPrivilege('biz.price.plan.edit');
        },
    },
    filters: {
        valid(valid) {
            if (typeof valid == 'undefined') {
                return '';
            }
            if (valid == 'true') {
                return '同意';
            }
            if (valid == 'false') {
                return '不同意';
            }
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
